import React from 'react';
import { Container, Grid, Typography, Box, Avatar, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import blog15 from '../../../src/Images/blog15.jpg'; // Adjust the image path as needed
import { Helmet } from 'react-helmet';

const Blog15 = () => {
    return (
        <Container maxWidth="lg" sx={{ backgroundColor: '#ffffff', padding: 4, borderRadius: 2 }}>
            <Helmet>
                <title>Holistic Homeopathic Approach To Conjunctivitis At Dr. Vandna Kanani’s Clinic</title>
                <meta name="description" content="Discover holistic homeopathic treatments for conjunctivitis at Dr. Vandna Kanani’s clinic. Learn how personalized care can improve eye health and overall well-being." />
                <meta name="keywords" content="Conjunctivitis, Pink Eye, Homeopathy, Dr. Vandna Kanani, Predictive Homeopathy, Eye Health, Natural Remedies, Holistic Care" />
            </Helmet>

            <Box component="header" textAlign="center" my={{ xs: 3, md: 5 }}>
                <Typography variant="h3" component="h1" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                    Holistic Homeopathic Approach To Conjunctivitis At Dr. Vandna Kanani’s Clinic
                </Typography>
            </Box>
            <Grid container spacing={4} component="article">
                <Grid item xs={12}>
                    <Box
                        sx={{
                            width: '100%',
                            paddingTop: '53.23%', // Adjust aspect ratio as needed
                            position: 'relative',
                            backgroundColor: '#fff',
                            borderRadius: 2,
                            overflow: 'hidden',
                        }}
                    >
                        <Avatar
                            variant="square"
                            src={blog15}
                            alt="Image related to conjunctivitis treatment at Dr. Vandna Kanani’s clinic"
                            sx={{ 
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover', 
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box mt={{ xs: 3, md: 5 }}>
                        <Typography variant="body1" paragraph sx={{ color: '#555', lineHeight: 1.7 }}>
                            Conjunctivitis, commonly known as pink eye, is an inflammation or infection of the conjunctiva—the transparent membrane covering the white part of the eyeball and the inner eyelid. It can cause redness, itching, tearing, and a gritty feeling in the eye, as well as discharge that can crust over the eyelashes. While it can be uncomfortable and sometimes contagious, conjunctivitis is typically not serious and can be effectively managed with proper treatment. At Dr. Vandna Kanani’s clinic, we specialize in using predictive homeopathy to provide holistic and personalized care for patients suffering from conjunctivitis. Our approach focuses on addressing both the symptoms and the underlying causes of the condition, ensuring a comprehensive treatment plan that promotes overall eye health and well-being.
                        </Typography>
                        <Section
                            title="Understanding Conjunctivitis"
                            content={
                                <>
                                    Conjunctivitis can be caused by a variety of factors, including:
                                    <List>
                                        <ListItem sx={{ alignItems: 'flex-start' }}>
                                            <ListItemIcon>
                                                <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Viral Infections: Often associated with the common cold, viral conjunctivitis is highly contagious and can spread easily." />
                                        </ListItem>
                                        <ListItem sx={{ alignItems: 'flex-start' }}>
                                            <ListItemIcon>
                                                <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Bacterial Infections: Bacterial conjunctivitis can cause more severe symptoms, including significant redness, swelling, and yellow-green discharge." />
                                        </ListItem>
                                        <ListItem sx={{ alignItems: 'flex-start' }}>
                                            <ListItemIcon>
                                                <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Allergic Reactions: Allergens such as pollen, dust, or pet dander can trigger allergic conjunctivitis, leading to itching, redness, and watery eyes." />
                                        </ListItem>
                                        <ListItem sx={{ alignItems: 'flex-start' }}>
                                            <ListItemIcon>
                                                <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Irritants: Exposure to irritants like smoke, chemicals, or chlorine can cause conjunctivitis symptoms." />
                                        </ListItem>
                                    </List>
                                    Each type of conjunctivitis requires a tailored treatment approach to effectively manage symptoms and prevent recurrence.
                                </>
                            }
                        />
                        <Section
                            title="Predictive Homeopathy: A Comprehensive Approach to Conjunctivitis"
                            content="At Dr. Vandna Kanani’s clinic, we utilize predictive homeopathy to provide a holistic and individualized approach to treating conjunctivitis. This method is not only focused on alleviating symptoms but also on understanding and addressing the root causes of the condition."
                        />
                        <Section
                            title="Key Features of Our Homeopathic Approach"
                            content={
                                <List>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Thorough Assessment:</strong>} 
                                            secondary="We begin with a comprehensive assessment of the patient’s symptoms, medical history, lifestyle, and any potential triggers for conjunctivitis. This helps us identify the specific type and cause of the condition." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Personalized Homeopathic Remedies:</strong>} 
                                            secondary="Based on the assessment, we prescribe individualized homeopathic remedies that are tailored to the patient’s unique needs. These remedies are designed to strengthen the immune system, reduce inflammation, and alleviate symptoms." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Holistic Care:</strong>} 
                                            secondary="Our treatment plan includes guidance on lifestyle modifications, dietary recommendations, and preventive measures to avoid future occurrences of conjunctivitis. We also emphasize the importance of eye hygiene and proper care." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Safe and Gentle Treatment:</strong>} 
                                            secondary="Homeopathic remedies are derived from natural substances and are gentle on the body, making them safe for all age groups, including children and sensitive individuals." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                </List>
                            }
                        />
                        <Section
                            title="Treatment Process at Dr. Vandna Kanani’s Clinic"
                            content={
                                <>
                                    <strong>Initial Consultation:</strong> The process starts with an in-depth consultation where we gather detailed information about the patient’s eye symptoms, overall health, and any potential triggers for conjunctivitis.
                                    <br/><br/>
                                    <strong>Customized Treatment Plan:</strong> We develop a personalized treatment plan that includes specific homeopathic remedies and lifestyle recommendations aimed at addressing the underlying causes and symptoms of conjunctivitis.
                                    <br/><br/>
                                    <strong>Ongoing Monitoring and Support:</strong> Regular follow-up sessions are scheduled to monitor progress and adjust the treatment plan as needed. We provide continuous support to ensure the best possible outcomes.
                                    <br/><br/>
                                    <strong>Focus on Long-Term Eye Health:</strong> Our approach emphasizes long-term eye health and prevention. We work with patients to identify and avoid triggers, promote healthy habits, and strengthen the immune system.
                                </>
                            }
                        />
                        <Section
                            title="Benefits of Homeopathic Treatment for Conjunctivitis"
                            content={
                                <List>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Natural and Non-Invasive:</strong>} 
                                            secondary="Homeopathic treatments are natural and do not involve harsh chemicals or invasive procedures, making them suitable for all patients." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Individualized Care:</strong>} 
                                            secondary="Each patient receives a treatment plan tailored to their specific needs and the underlying causes of their condition." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Holistic Approach:</strong>} 
                                            secondary="We consider the whole person, including physical, emotional, and environmental factors, to provide comprehensive care." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Preventive Focus:</strong>} 
                                            secondary="Our treatment aims to prevent recurrence and promote overall eye health and well-being." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                </List>
                            }
                        />
                        <Section
                            title="Why Choose Dr. Vandna Kanani’s Homeopathy Clinic?"
                            content="Dr. Vandna Kanani is a renowned practitioner in the field of predictive homeopathy, known for her compassionate and thorough approach to patient care. With extensive experience and a deep understanding of homeopathy, Dr. Kanani offers personalized and effective treatment for a wide range of conditions, including conjunctivitis. At our clinic, we believe in the power of natural healing and strive to provide treatments that not only address the symptoms but also enhance overall health and well-being. Our goal is to help patients achieve long-lasting relief and prevent future occurrences of conjunctivitis."
                        />
                        <Section
                            title="Conclusion"
                            content="If you are experiencing symptoms of conjunctivitis, Dr. Vandna Kanani’s homeopathy clinic offers a comprehensive and holistic approach to treatment. Through predictive homeopathy, we provide individualized care that addresses both the symptoms and root causes of the condition, promoting lasting relief and improved eye health. For more information or to schedule a consultation, please visit our website or contact Dr. Vandna Kanani’s clinic today. Let us help you achieve clear and healthy eyes through natural and holistic care."
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

// Reusable Section Component
const Section = ({ title, content }) => (
    <Box mb={{ xs: 3, md: 4 }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
            {title}
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: '#666', lineHeight: 1.8 }}>
            {content}
        </Typography>
    </Box>
);

export default Blog15;
