import React from 'react';
import { Container, Grid, Typography, Box, Avatar, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import blog5 from '../../../src/Images/blog5.jpg';
import { Helmet } from 'react-helmet';

const Blog5 = () => {
    return (
        <Container maxWidth="lg" sx={{ backgroundColor: '#ffffff', padding: 4, borderRadius: 2 }}>
            <Helmet>
                <title>Best Homeopathic Treatment For Diabetes At Dr. Vandna Kanani’s Clinic</title>
                <meta name="description" content="Discover specialized homeopathic treatments for diabetes at Dr. Vandna Kanani's clinic. Learn how predictive homeopathy can help manage diabetes and improve overall well-being." />
                <meta name="keywords" content="Diabetes, Homeopathy, Dr. Vandna Kanani, Predictive Homeopathy, Holistic Care" />
            </Helmet>

            <Box component="header" textAlign="center" my={{ xs: 3, md: 5 }}>
                <Typography variant="h3" component="h1" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                    Best Homeopathic Treatment For Diabetes At Dr. Vandna Kanani’s Clinic
                </Typography>
            </Box>
            <Grid container spacing={4} component="article">
                <Grid item xs={12}>
                    <Box
                        sx={{
                            width: '100%',
                            paddingTop: '53.23%', // 330/620 * 100 = 53.23% for maintaining 620:330 aspect ratio
                            position: 'relative',
                            backgroundColor: '#fff',
                            borderRadius: 2,
                            overflow: 'hidden',
                        }}
                    >
                        <Avatar
                            variant="square"
                            src={blog5}
                            alt="Diabetes care at Dr. Vandna Kanani’s clinic"
                            sx={{ 
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover', 
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box mt={{ xs: 3, md: 5 }}>
                        <Typography variant="body1" paragraph sx={{ color: '#555', lineHeight: 1.7 }}>
                            Dr. Vandna Kanani’s homeopathy clinic offers specialized care for managing diabetes through a comprehensive and personalized approach...
                        </Typography>
                        <Section
                            title="Understanding Diabetes"
                            content="Diabetes is a chronic condition characterized by high blood sugar levels, either due to insufficient insulin production (Type 1 diabetes) or the body's ineffective use of insulin (Type 2 diabetes)..."
                        />
                        <Section
                            title="The Predictive Homeopathy Approach"
                            content="Predictive homeopathy, a specialty of Dr. Vandna Kanani, involves a detailed assessment of the patient's health history, lifestyle, genetic predispositions, and environmental factors..."
                        />
                        <Section
                            title="Comprehensive Treatment Plans for Diabetes"
                            content={
                                <List>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Customized Homeopathic Remedies:</strong>} 
                                            secondary="Dr. Kanani prescribes individualized homeopathic remedies tailored to the specific needs of each patient..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Diet and Lifestyle Guidance:</strong>} 
                                            secondary="Proper nutrition and lifestyle choices are vital in managing diabetes. Dr. Kanani provides personalized dietary plans..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Education and Empowerment:</strong>} 
                                            secondary="Dr. Kanani believes in empowering patients with knowledge about their condition. She offers education on the importance of monitoring blood sugar levels..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Regular Monitoring and Support:</strong>} 
                                            secondary="Ongoing care and monitoring are essential in diabetes management. Dr. Kanani offers regular follow-up consultations..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                </List>
                            }
                        />
                        <Section
                            title="Benefits of Homeopathic Treatment for Diabetes"
                            content={
                                <List>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Natural and Safe:</strong>} 
                                            secondary="Homeopathic remedies are made from natural substances and are free from harmful side effects, making them safe for long-term use..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Holistic Care:</strong>} 
                                            secondary="Homeopathy treats the whole person, addressing not only the physical symptoms but also the emotional and mental aspects of health..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Individualized Treatment:</strong>} 
                                            secondary="Each patient receives a treatment plan customized to their unique health needs and constitution..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Focus on Prevention:</strong>} 
                                            secondary="By addressing the root causes and promoting overall health, homeopathy helps prevent the progression of diabetes..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                </List>
                            }
                        />
                        <Section
                            title="Dr. Vandna Kanani: A Trusted Expert"
                            content="Dr. Vandna Kanani is a highly regarded practitioner known for her expertise in predictive homeopathy and her compassionate approach to patient care..."
                        />
                        <Section
                            title="Conclusion"
                            content="Managing diabetes requires a comprehensive and personalized approach. At Dr. Vandna Kanani’s clinic, patients receive expert care that goes beyond symptom control to address the root causes of diabetes..."
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

// Reusable Section Component
const Section = ({ title, content }) => (
    <Box mb={{ xs: 3, md: 4 }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
            {title}
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: '#666', lineHeight: 1.8 }}>
            {content}
        </Typography>
    </Box>
);

export default Blog5;
