import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './App.css';
import AuthProvider from './Context/AuthProvider';
import About from './Pages/About/About/About';
import Approved from './Pages/Approved/Approved';
import Blog1 from './Pages/Blog1/Blog1';
import Blog2 from './Pages/Blog2/Blog2';
import Blog3 from './Pages/Blog3/Blog3';
import Blog4 from './Pages/Blog4/Blog4';
import Blog5 from './Pages/Blog5/Blog5';
import Blog6 from './Pages/Blog6/Blog6';
import Blog7 from './Pages/Blog7/Blog7';
import Blog8 from './Pages/Blog8/Blog8';
import Blog9 from './Pages/Blog9/Blog9';
import Blog10 from './Pages/Blog10/Blog10';
import Blog11 from './Pages/Blog11/Blog11';
import Blog12 from './Pages/Blog12/Blog12';
import Blog13 from './Pages/Blog13/Blog13';
import Blog14 from './Pages/Blog14/Blog14';
import Blog15 from './Pages/Blog15/Blog15';
import Blog16 from './Pages/Blog16/Blog16';
import Blog17 from './Pages/Blog17/Blog17';
import Blog18 from './Pages/Blog18/Blog18';
import Contact from './Pages/Contact/Contact/Contact';
import Dentist from './Pages/Dentist/Denitst/Dentist';
import Footer from './Pages/Home/Footer/Footer.jsx';
import Header from './Pages/Home/Header/Header.jsx';
import Home from './Pages/Home/Home/Home.jsx';
import Login from './Pages/Login/Login';
import NotFound from './Pages/NotFound/NotFound';
import Service from './Pages/Services/Service/Service';
import WhatsAppButton from './components/WhatsAppButton/WhatsAppButton';

const SEO = ({ title, description, keywords }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />
    <meta name="robots" content="index, follow" />
    <meta name="author" content="Dr. Vandna Kanani" />
  </Helmet>
);

const SEOManager = () => {
  const location = useLocation();

  const getPageSEO = (path) => {
    switch (path) {
      case '/':
      case '/home':
        return {
          title: 'Dr. Vandna Kanani - Best Homeopathy in Surat and India',
          description: 'Dr. Vandna Kanani provides expert homeopathy services in Surat and across India. Book your appointment now.',
          keywords: 'homeopathy, Surat, India, Dr. Vandna Kanani, alternative medicine, health care',
        };
      case '/about':
        return {
          title: 'About Dr. Vandna Kanani - Homeopathy Specialist in Surat',
          description: 'Learn more about Dr. Vandna Kanani, a leading homeopathy specialist in Surat and India.',
          keywords: 'About Dr. Vandna Kanani, homeopathy, Surat, India, alternative medicine',
        };
      case '/service':
        return {
          title: 'Homeopathy Services - Dr. Vandna Kanani',
          description: 'Explore the range of homeopathy services offered by Dr. Vandna Kanani in Surat and across India.',
          keywords: 'homeopathy services, Dr. Vandna Kanani, Surat, India, health care',
        };
      case '/contact':
        return {
          title: 'Contact Dr. Vandna Kanani - Homeopathy in Surat',
          description: 'Get in touch with Dr. Vandna Kanani for expert homeopathy services in Surat and across India.',
          keywords: 'contact, Dr. Vandna Kanani, homeopathy, Surat, India, appointment',
        };
      // Add more cases for different pages
      default:
        return {
          title: 'Dr. Vandna Kanani - Best Homeopathy in Surat and India',
          description: 'Dr. Vandna Kanani provides expert homeopathy services in Surat and across India. Book your appointment now.',
          keywords: 'homeopathy, Surat, India, Dr. Vandna Kanani, alternative medicine, health care',
        };
    }
  };

  const seo = getPageSEO(location.pathname);

  return <SEO title={seo.title} description={seo.description} keywords={seo.keywords} />;
};

function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
        <Router>
          <Header />
          <SEOManager />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/home' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/service' element={<Service />} />
            <Route path='/drvandna' element={<Dentist />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/approved' element={<Approved />} />
            <Route path='/blog1' element={<Blog1 />} />
            <Route path='/blog2' element={<Blog2 />} />
            <Route path='/blog3' element={<Blog3 />} />
            <Route path='/blog4' element={<Blog4 />} />
            <Route path='/blog5' element={<Blog5 />} />
            <Route path='/blog6' element={<Blog6 />} />
            <Route path='/blog7' element={<Blog7 />} />
            <Route path='/blog8' element={<Blog8 />} />
            <Route path='/blog9' element={<Blog9 />} />
            <Route path='/blog10' element={<Blog10 />} />
            <Route path='/blog11' element={<Blog11 />} />
            <Route path='/blog12' element={<Blog12 />} />
            <Route path='/blog13' element={<Blog13 />} />
            <Route path='/blog14' element={<Blog14 />} />
            <Route path='/blog15' element={<Blog15 />} />
            <Route path='/blog16' element={<Blog16 />} />
            <Route path='/blog17' element={<Blog17 />} />
            <Route path='/blog18' element={<Blog18 />} />
            <Route path='/login' element={<Login />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
          <Footer />
          <WhatsAppButton />
        </Router>
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;
