import React from "react";
import { Col, Container, NavLink, Row } from "react-bootstrap";
import "./Footer.css";
import logo from "../../../Images/icon-footer-dark1.png";
import SocialIcons from "./SocialIcons";

const Footer = () => {
  return (
    <div className="footer-bg">
      <Container>
        <Row className="text-white footer-row">
          <Col xs={12} md={3} className="mb-4 d-flex flex-column align-items-center">
            <div className="footer-logo-container text-center">
              <a href="/">
                <img src={logo} alt="Logo" height="120" />
              </a>
              <SocialIcons />
            </div>
          </Col>
          <Col xs={12} md={3} className="mb-4">
            <div className="single-footer-widget">
              <div className="widget-title">
                <h2>Practices</h2>
              </div>
              <div className="widget-content">
                <NavLink href="/drvandna" className="footer-link">Dr Vandna</NavLink>
                <NavLink href="/about" className="footer-link">About</NavLink>
                <NavLink href="/contact" className="footer-link">Contact Us</NavLink>
                <NavLink href="https://drvandnahomeopathy.com/blogs/" className="footer-link" target="_blank" rel="noopener noreferrer">Blog</NavLink>
              </div>
            </div>
          </Col>
          <Col xs={12} md={3} className="mb-4">
            <div className="single-footer-widget">
              <div className="widget-title">
                <h2>Treatments</h2>
              </div>
              <div className="widget-content">
                <NavLink href="/blog1" className="footer-link">Respiratory Diseases</NavLink>
                <NavLink href="/blog2" className="footer-link">Kidney Diseases</NavLink>
                <NavLink href="/blog3" className="footer-link">Skin Diseases</NavLink>
                <NavLink href="/blog4" className="footer-link">Liver Conditions</NavLink>
                <NavLink href="/blog5" className="footer-link">Diabetes</NavLink>
                <NavLink href="/blog6" className="footer-link">Migraine</NavLink>
                <NavLink href="/blog7" className="footer-link">Rheumatoid Arthritis</NavLink>
                <NavLink href="/blog8" className="footer-link">Piles</NavLink>
                <NavLink href="/blog9" className="footer-link">Knee Pain</NavLink>
                <NavLink href="/blog10" className="footer-link">Body Pain</NavLink>
                <NavLink href="/blog11" className="footer-link">Headaches</NavLink>
                <NavLink href="/blog12" className="footer-link">Joint Pain And Stiffness</NavLink>
              </div>
            </div>
          </Col>
          <Col xs={12} md={3} className="mb-4 d-flex flex-column align-items-center">
            <div className="single-footer-widget">
              <div className="widget-title">
                <h2>Our Address</h2>
              </div>
              <div className="widget-content text-center">
                <p>71, Shankar Nagar Society, Lalita Chokdi, Katargam, Surat-395004</p>
                <NavLink href="mailto:dr.vandnaclinic@gmail.com" className="footer-link">Email: dr.vandnaclinic@gmail.com</NavLink>
                <NavLink href="tel:+918780642122" className="footer-link">+91 87806 42122</NavLink>
              </div>
            </div>
            {/* Google Maps Embed */}
            <div className="google-maps-container mt-4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3718.977708443969!2d72.82703098223092!3d21.232732530399062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f83b9c3df17%3A0x66b718efbc6603d3!2sShreeja%20Homeopathic%20Clinic%20Surat!5e0!3m2!1sen!2sin!4v1723206034543!5m2!1sen!2sin"
                width="100%" height="250" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade">
              </iframe>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="footer-copy-right text-center text-white">
        <p className="mb-0">
          &copy; 2024 - Developed by <a href="https://www.srjay.com/" className="developer-link">S R Jay Kikani</a> & <a href="https://www.linkedin.com/in/harsh-trada-b0a119258/?originalSubdomain=in" className="developer-link">Harsh Trada</a> | All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
