import React from 'react';
import { Container, Grid, Typography, Box, Avatar, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import blog6 from '../../../src/Images/blog6.jpg';
import { Helmet } from 'react-helmet';

const Blog6 = () => {
    return (
        <Container maxWidth="lg" sx={{ backgroundColor: '#ffffff', padding: 4, borderRadius: 2 }}>
            <Helmet>
                <title>Expert Homeopathic Treatment For Migraine At Dr. Vandna Kanani’s Clinic</title>
                <meta name="description" content="Explore expert homeopathic treatments for migraines at Dr. Vandna Kanani's clinic. Learn how predictive homeopathy can offer long-term relief from migraine headaches." />
                <meta name="keywords" content="Migraine, Homeopathy, Dr. Vandna Kanani, Predictive Homeopathy, Holistic Care" />
            </Helmet>

            <Box component="header" textAlign="center" my={{ xs: 3, md: 5 }}>
                <Typography variant="h3" component="h1" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                    Expert Homeopathic Treatment For Migraine At Dr. Vandna Kanani’s Clinic
                </Typography>
            </Box>
            <Grid container spacing={4} component="article">
                <Grid item xs={12}>
                    <Box
                        sx={{
                            width: '100%',
                            paddingTop: '53.23%', // 330/620 * 100 = 53.23% for maintaining 620:330 aspect ratio
                            position: 'relative',
                            backgroundColor: '#fff',
                            borderRadius: 2,
                            overflow: 'hidden',
                        }}
                    >
                        <Avatar
                            variant="square"
                            src={blog6}
                            alt="Migraine care at Dr. Vandna Kanani’s clinic"
                            sx={{ 
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover', 
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box mt={{ xs: 3, md: 5 }}>
                        <Typography variant="body1" paragraph sx={{ color: '#555', lineHeight: 1.7 }}>
                            At Dr. Vandna Kanani’s clinic, the treatment of migraines is approached through predictive homeopathy—a specialized and holistic method aimed at identifying and addressing the root causes of this debilitating condition...
                        </Typography>
                        <Section
                            title="Understanding Migraines"
                            content="Migraines are intense, recurring headaches often accompanied by nausea, vomiting, and sensitivity to light and sound..."
                        />
                        <Section
                            title="The Predictive Homeopathy Approach"
                            content="Predictive homeopathy, as practiced by Dr. Vandna Kanani, focuses on understanding the individual’s unique health profile, including their medical history, lifestyle, and genetic predispositions..."
                        />
                        <Section
                            title="Comprehensive Treatment Plans for Migraines"
                            content={
                                <List>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Personalized Homeopathic Remedies:</strong>} 
                                            secondary="Dr. Kanani prescribes custom homeopathic remedies based on each patient’s specific symptoms and overall health..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Diet and Lifestyle Recommendations:</strong>} 
                                            secondary="Understanding the triggers that contribute to migraines is crucial. Dr. Kanani provides dietary guidance to help patients avoid foods that may provoke headaches..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Stress Management Techniques:</strong>} 
                                            secondary="Stress is a common trigger for migraines. Dr. Kanani incorporates stress management strategies, such as relaxation techniques..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Education and Empowerment:</strong>} 
                                            secondary="Dr. Kanani believes in empowering her patients with knowledge about their condition. She educates them on how to identify and manage potential triggers..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Regular Monitoring and Follow-up:</strong>} 
                                            secondary="Continuous care is essential for effectively managing migraines. Dr. Kanani offers regular follow-up consultations to monitor progress..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                </List>
                            }
                        />
                        <Section
                            title="Benefits of Homeopathic Treatment for Migraines"
                            content={
                                <List>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Natural and Safe:</strong>} 
                                            secondary="Homeopathic remedies are derived from natural sources and are free from side effects, making them safe for long-term use..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Holistic Care:</strong>} 
                                            secondary="Homeopathy treats the whole person, addressing not only the physical symptoms but also the emotional and mental aspects..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Customized Treatment:</strong>} 
                                            secondary="Each patient receives a tailored treatment plan based on their unique symptoms and health profile, ensuring optimal care..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Focus on Prevention:</strong>} 
                                            secondary="By addressing the root causes and promoting overall health, homeopathy helps prevent the recurrence and severity of migraines..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                </List>
                            }
                        />
                        <Section
                            title="Dr. Vandna Kanani: A Trusted Migraine Specialist"
                            content="Dr. Vandna Kanani is a respected expert in the field of homeopathy, known for her compassionate approach and dedication to patient care..."
                        />
                        <Section
                            title="Conclusion"
                            content="At Dr. Vandna Kanani’s clinic, patients suffering from migraines receive comprehensive and personalized care designed to address the root causes of their condition..."
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

// Reusable Section Component
const Section = ({ title, content }) => (
    <Box mb={{ xs: 3, md: 4 }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
            {title}
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: '#666', lineHeight: 1.8 }}>
            {content}
        </Typography>
    </Box>
);

export default Blog6;
