import React from 'react';
import { Container, Grid, Typography, Box, Avatar, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import blog10 from '../../../src/Images/blog10.jpg';
import { Helmet } from 'react-helmet';

const Blog10 = () => {
    return (
        <Container maxWidth="lg" sx={{ backgroundColor: '#ffffff', padding: 4, borderRadius: 2 }}>
            <Helmet>
                <title>Best Homeopathy Treatment For Body Pain At Dr. Vandna Kanani’s Clinic</title>
                <meta name="description" content="Explore effective homeopathic treatments for body pain at Dr. Vandna Kanani's clinic. Learn about holistic care approaches to relieve pain and improve well-being." />
                <meta name="keywords" content="Body Pain, Homeopathy, Dr. Vandna Kanani, Predictive Homeopathy, Holistic Care" />
            </Helmet>

            <Box component="header" textAlign="center" my={{ xs: 3, md: 5 }}>
                <Typography variant="h3" component="h1" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                    Best Homeopathy Treatment For Body Pain At Dr. Vandna Kanani’s Clinic
                </Typography>
            </Box>
            <Grid container spacing={4} component="article">
                <Grid item xs={12}>
                    <Box
                        sx={{
                            width: '100%',
                            paddingTop: '53.23%', // Adjust aspect ratio as needed
                            position: 'relative',
                            backgroundColor: '#fff',
                            borderRadius: 2,
                            overflow: 'hidden',
                        }}
                    >
                        <Avatar
                            variant="square"
                            src={blog10}
                            alt="Image related to body pain treatment at Dr. Vandna Kanani’s clinic"
                            sx={{ 
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover', 
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box mt={{ xs: 3, md: 5 }}>
                        <Typography variant="body1" paragraph sx={{ color: '#555', lineHeight: 1.7 }}>
                            Body pain can manifest in various forms and intensities, affecting overall well-being and quality of life...
                        </Typography>
                        <Section
                            title="Understanding Body Pain"
                            content={
                                <>
                                    Body pain can arise from multiple sources, including:
                                    <List>
                                        <ListItem sx={{ alignItems: 'flex-start' }}>
                                            <ListItemIcon>
                                                <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Musculoskeletal Issues: Such as arthritis, fibromyalgia, or injuries." />
                                        </ListItem>
                                        <ListItem sx={{ alignItems: 'flex-start' }}>
                                            <ListItemIcon>
                                                <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Nervous System Disorders: Including neuropathic pain or neuralgia." />
                                        </ListItem>
                                        <ListItem sx={{ alignItems: 'flex-start' }}>
                                            <ListItemIcon>
                                                <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Inflammatory Conditions: Like bursitis or tendinitis." />
                                        </ListItem>
                                        <ListItem sx={{ alignItems: 'flex-start' }}>
                                            <ListItemIcon>
                                                <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Chronic Conditions: Such as chronic fatigue syndrome or autoimmune diseases." />
                                        </ListItem>
                                        <ListItem sx={{ alignItems: 'flex-start' }}>
                                            <ListItemIcon>
                                                <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Lifestyle Factors: Poor posture, lack of exercise, or stress." />
                                        </ListItem>
                                    </List>
                                </>
                            }
                        />
                        <Section
                            title="Predictive Homeopathy: A Personalized Approach"
                            content="At Dr. Vandna Kanani’s clinic, we utilize predictive homeopathy, a unique approach that goes beyond merely treating symptoms..."
                        />
                        <Section
                            title="Key Aspects of Predictive Homeopathy for Body Pain"
                            content={
                                <List>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Detailed Patient Assessment:</strong>} 
                                            secondary="We conduct a thorough evaluation of each patient's health, including physical, emotional, and psychological aspects..." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Individualized Treatment Plans:</strong>} 
                                            secondary="Based on the assessment, Dr. Kanani develops a personalized treatment plan that includes homeopathic remedies tailored to the specific needs of the patient..." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Holistic Care:</strong>} 
                                            secondary="Our approach integrates lifestyle and dietary recommendations, stress management techniques, and exercises to complement the homeopathic treatment..." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Natural and Safe Remedies:</strong>} 
                                            secondary="Homeopathic medicines are derived from natural substances and are prepared in a way that enhances their healing properties while minimizing side effects..." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                </List>
                            }
                        />
                        <Section
                            title="Treatment Process at Dr. Vandna Kanani’s Clinic"
                            content="The treatment process begins with an initial consultation, where Dr. Kanani gathers detailed information about the patient’s symptoms, health history, and lifestyle..."
                        />
                        <Section
                            title="Benefits of Homeopathic Treatment for Body Pain"
                            content={
                                <List>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Targeted Relief:</strong>} 
                                            secondary="Homeopathic remedies are selected based on the individual’s specific symptoms and condition, providing targeted relief from body pain..." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Comprehensive Care:</strong>} 
                                            secondary="The holistic approach ensures that all aspects of the patient’s health are considered, promoting overall well-being..." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Safe and Natural:</strong>} 
                                            secondary="Homeopathic treatments are natural and free from harmful side effects, making them a safe option for all ages..." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Empowerment and Education:</strong>} 
                                            secondary="Patients are educated about their condition and treatment options, empowering them to take an active role in their healing process..." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                </List>
                            }
                        />
                        <Section
                            title="Why Choose Dr. Vandna Kanani’s Homeopathy Clinic?"
                            content="Dr. Vandna Kanani is a renowned expert in predictive homeopathy, known for her compassionate care and commitment to patient well-being..."
                        />
                        <Section
                            title="Conclusion"
                            content="If you are experiencing body pain and seeking a natural, holistic approach to treatment, Dr. Vandna Kanani’s homeopathy clinic offers a compassionate and effective solution..."
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

// Reusable Section Component
const Section = ({ title, content }) => (
    <Box mb={{ xs: 3, md: 4 }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
            {title}
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: '#666', lineHeight: 1.8 }}>
            {content}
        </Typography>
    </Box>
);

export default Blog10;
