import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AboutFocus } from "../../FakeData/AboutFocus";
import Services from "../../Pages/Home/Services/Services";

const Service = () => {
  return (
    <section className="service-wrapper" style={{ marginBottom: "65px" }}>
      <Container style={{marginTop:"-50px"}}>
        <Row>
          <Col sm={12}>
            <div className="section-title text-center">
              <h1>Patient Focused Approach</h1>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col lg={6}>
            <p
              className="m-auto content-inner"
              style={{ color: "white", width: "85%" }}
            >
              At Dr. Vandna Kanani's clinic, our patient-focused approach is at
              the heart of everything we do. We believe in providing
              personalized, compassionate care that addresses the unique needs
              and concerns of each individual. Our goal is to not only treat
              symptoms but to enhance overall well-being by focusing on the root
              causes of health issues.
            </p>
            <br />
            <p
              className="m-auto content-inner"
              style={{ color: "white", width: "85%" }}
            >
              <strong>Comprehensive Assessments:</strong> We begin each
              patient’s journey with a thorough assessment of their medical
              history, lifestyle, diet, and emotional state. This holistic
              evaluation helps us understand the underlying factors contributing
              to their health conditions.
            </p>
            <br />
            <p
              className="m-auto content-inner"
              style={{ color: "white", width: "85%" }}
            >
              <strong>Personalized Treatment Plans:</strong> Every patient
              receives a customized treatment plan tailored to their specific
              needs and health profile. By selecting homeopathic remedies that
              address both symptoms and root causes, we ensure effective and
              lasting results.
            </p>
            <br />
            <p
              className="m-auto content-inner"
              style={{ color: "white", width: "85%" }}
            >
              <strong>Holistic Care:</strong> Our approach encompasses the
              physical, emotional, and psychological aspects of health. We
              believe that true healing involves considering all dimensions of a
              person’s well-being, ensuring a comprehensive approach to health
              care.
            </p>
            <br />
            <p
              className="m-auto content-inner"
              style={{ color: "white", width: "85%" }}
            >
              <strong>Education and Empowerment:</strong> We are dedicated to
              educating our patients about their health conditions and treatment
              options. By empowering them with knowledge, we enable them to take
              an active role in their health and wellness journey.
            </p>
          </Col>
          <Col lg={6}>
            <p
              className="m-auto content-inner"
              style={{ color: "white", width: "85%" }}
            >
              <strong>Ongoing Support:</strong> Healing is a continuous process,
              and we provide regular follow-up consultations to monitor progress
              and make necessary adjustments to treatment plans. Our commitment
              to ongoing support ensures that our patients receive the best
              possible care.
            </p>
            <br />
            <p
              className="m-auto content-inner"
              style={{ color: "white", width: "85%" }}
            >
              <strong>Natural and Safe Remedies:</strong> Our homeopathic
              remedies are derived from natural sources and are known for their
              safety and lack of side effects. This makes them suitable for
              long-term use and contributes to the overall well-being of our
              patients.
            </p>
            <br />
            <p
              className="m-auto content-inner"
              style={{ color: "white", width: "85%" }}
            >
              <strong>Compassionate Care:</strong> We treat each patient with
              empathy, respect, and understanding. Our supportive environment
              fosters healing and well-being, ensuring that patients feel valued
              and cared for throughout their treatment.
            </p>
            <br />
            <p
              className="m-auto content-inner"
              style={{ color: "white", width: "85%" }}
            >
              <strong>Focus on Prevention:</strong> In addition to treating
              existing conditions, we emphasize preventive care. By addressing
              lifestyle factors and promoting healthy habits, we help our
              patients prevent future health issues and maintain long-term
              wellness.
            </p>
            <br />
            <p
              className=" m-auto content-inner"
              style={{ color: "white", width: "85%" }}
            >
              Our patient-focused approach reflects our commitment to providing
              high-quality, holistic healthcare. At Dr. Vandna Kanani's clinic,
              we strive to create a positive and transformative experience for
              each patient, guiding them towards optimal health and a better
              quality of life.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
{
  /* {
              AboutFocus.map(treatment => (
                  <Services key={treatment.id} treatment={treatment} />
              ))
          } */
}

export default Service;
