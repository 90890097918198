import React from 'react';
import { Container, Grid, Typography, Box, Avatar, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import blog18 from '../../../src/Images/blog18.jpg'; // Adjust the image path as needed
import { Helmet } from 'react-helmet';

const Blog18 = () => {
    return (
        <Container maxWidth="lg" sx={{ backgroundColor: '#ffffff', padding: 4, borderRadius: 2 }}>
            <Helmet>
                <title>Homeopathic Treatment For High Blood Pressure At Dr. Vandna Kanani’s Clinic</title>
                <meta name="description" content="Explore the holistic approach to treating high blood pressure at Dr. Vandna Kanani’s clinic through predictive homeopathy, focusing on both symptom relief and root cause treatment." />
                <meta name="keywords" content="High Blood Pressure, Hypertension, Homeopathy, Dr. Vandna Kanani, Predictive Homeopathy, Natural Remedies, Holistic Care, Cardiovascular Health" />
            </Helmet>

            <Box component="header" textAlign="center" my={{ xs: 3, md: 5 }}>
                <Typography variant="h3" component="h1" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                    Homeopathic Treatment For High Blood Pressure At Dr. Vandna Kanani’s Clinic
                </Typography>
            </Box>
            <Grid container spacing={4} component="article">
                <Grid item xs={12}>
                    <Box
                        sx={{
                            width: '100%',
                            paddingTop: '53.23%', // Adjust aspect ratio as needed
                            position: 'relative',
                            backgroundColor: '#fff',
                            borderRadius: 2,
                            overflow: 'hidden',
                        }}
                    >
                        <Avatar
                            variant="square"
                            src={blog18}
                            alt="Image related to homeopathic treatment for high blood pressure at Dr. Vandna Kanani’s clinic"
                            sx={{ 
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover', 
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box mt={{ xs: 3, md: 5 }}>
                        <Typography variant="body1" paragraph sx={{ color: '#555', lineHeight: 1.7 }}>
                            High blood pressure, also known as hypertension, is a common condition that can lead to serious health issues such as heart disease, stroke, and kidney problems if left untreated. At Dr. Vandna Kanani’s clinic, we offer a holistic approach to managing and treating high blood pressure through predictive homeopathy, focusing on both symptom relief and addressing the underlying causes.
                        </Typography>
                        <Section
                            title="Understanding High Blood Pressure"
                            content={
                                <>
                                    High blood pressure occurs when the force of blood against the walls of the arteries is consistently too high. It is often called the "silent killer" because it may not cause noticeable symptoms but can lead to serious health complications over time. Key factors contributing to high blood pressure include:
                                    <List>
                                        <ListItem sx={{ alignItems: 'flex-start' }}>
                                            <ListItemIcon>
                                                <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Genetics: A family history of hypertension can increase the risk." />
                                        </ListItem>
                                        <ListItem sx={{ alignItems: 'flex-start' }}>
                                            <ListItemIcon>
                                                <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Lifestyle Factors: Poor diet, lack of physical activity, and excessive alcohol or salt intake can contribute." />
                                        </ListItem>
                                        <ListItem sx={{ alignItems: 'flex-start' }}>
                                            <ListItemIcon>
                                                <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Stress: Chronic stress can elevate blood pressure levels." />
                                        </ListItem>
                                        <ListItem sx={{ alignItems: 'flex-start' }}>
                                            <ListItemIcon>
                                                <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Underlying Health Conditions: Conditions such as diabetes and obesity can increase the risk." />
                                        </ListItem>
                                    </List>
                                </>
                            }
                        />
                        <Section
                            title="The Predictive Homeopathy Approach"
                            content="Predictive homeopathy is a specialized branch of homeopathy that emphasizes understanding and treating the root causes of health issues. At Dr. Vandna Kanani’s clinic, we use this approach to provide personalized and comprehensive treatment plans for high blood pressure."
                        />
                        <Section
                            title="Key Features of Our Homeopathic Approach"
                            content={
                                <List>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>In-Depth Assessment:</strong>} 
                                            secondary="We conduct a thorough evaluation of the patient's medical history, lifestyle, diet, and emotional state to understand the factors contributing to high blood pressure." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Customized Treatment Plans:</strong>} 
                                            secondary="Each patient receives a personalized treatment plan tailored to their specific needs and health profile. Homeopathic remedies are selected to address both the symptoms and underlying causes of hypertension." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Holistic Care:</strong>} 
                                            secondary="Our treatments encompass physical, emotional, and psychological aspects of health, ensuring a holistic approach to managing high blood pressure." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Natural and Safe Remedies:</strong>} 
                                            secondary="Homeopathic remedies are derived from natural sources and are known for their safety and lack of side effects, making them suitable for long-term use." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                </List>
                            }
                        />
                        <Section
                            title="Treatment Process at Dr. Vandna Kanani’s Clinic"
                            content={
                                <>
                                    <strong>Initial Consultation:</strong> The treatment process begins with a detailed consultation to assess the patient's overall health, lifestyle, and specific symptoms related to high blood pressure.
                                    <br/><br/>
                                    <strong>Personalized Homeopathic Remedies:</strong> Based on the assessment, we prescribe homeopathic remedies that are specifically chosen to regulate blood pressure and address contributing factors such as stress, diet, and lifestyle.
                                    <br/><br/>
                                    <strong>Lifestyle and Dietary Guidance:</strong> We provide advice on dietary changes, stress management techniques, and lifestyle modifications that can help manage blood pressure effectively.
                                    <br/><br/>
                                    <strong>Ongoing Monitoring and Support:</strong> Regular follow-up consultations are scheduled to monitor the patient's progress and make necessary adjustments to the treatment plan.
                                    <br/><br/>
                                    <strong>Patient Education:</strong> We educate patients on the importance of maintaining a healthy lifestyle, managing stress, and adhering to their treatment plan for long-term health benefits.
                                </>
                            }
                        />
                        <Section
                            title="Benefits of Homeopathic Treatment for High Blood Pressure"
                            content={
                                <List>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Non-Invasive and Natural:</strong>} 
                                            secondary="Our treatments are non-invasive and use natural remedies that are safe for long-term use." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Personalized Care:</strong>} 
                                            secondary="Each treatment plan is customized to meet the unique needs and health conditions of the patient." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Focus on Root Causes:</strong>} 
                                            secondary="By addressing the underlying causes of high blood pressure, our treatments aim to provide long-term relief and prevent complications." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Holistic Approach:</strong>} 
                                            secondary="We consider the patient's overall well-being, including physical, emotional, and psychological aspects." 
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                </List>
                            }
                        />
                        <Section
                            title="Why Choose Dr. Vandna Kanani’s Homeopathy Clinic?"
                            content="Dr. Vandna Kanani is a renowned practitioner with extensive experience in predictive homeopathy. Her patient-centered approach and dedication to holistic healing have made her a trusted name in the field. At our clinic, patients receive compassionate care and a comprehensive approach to managing high blood pressure, focusing on long-term health and well-being. With a deep commitment to ongoing learning and patient education, Dr. Vandna Kanani ensures that each patient receives the highest standard of care tailored to their unique needs."
                        />
                        <Section
                            title="Conclusion"
                            content="High blood pressure is a serious condition, but with the right care, it can be managed effectively. At Dr. Vandna Kanani’s homeopathy clinic, we offer personalized, holistic treatment plans that address both the symptoms and root causes of hypertension. For more information or to schedule a consultation, please visit our website or contact us today. Let us help you achieve long-term health and well-being through natural and compassionate care."
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

// Reusable Section Component
const Section = ({ title, content }) => (
    <Box mb={{ xs: 3, md: 4 }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
            {title}
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: '#666', lineHeight: 1.8 }}>
            {content}
        </Typography>
    </Box>
);

export default Blog18;
