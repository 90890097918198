import React from 'react';
import { Container, Grid, Typography, Box, Avatar, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import blog4 from '../../../src/Images/blog4.jpg';
import { Helmet } from 'react-helmet';

const Blog4 = () => {
    return (
        <Container maxWidth="lg" sx={{ backgroundColor: '#ffffff', padding: 4, borderRadius: 2 }}>
            <Helmet>
                <title>Expert Homeopathic Treatment For Liver Conditions At Dr. Vandna Kanani’s Clinic</title>
                <meta name="description" content="Discover expert homeopathic treatments for liver conditions at Dr. Vandna Kanani's clinic. Learn how predictive homeopathy can enhance liver health and overall well-being." />
                <meta name="keywords" content="Liver Conditions, Homeopathy, Dr. Vandna Kanani, Predictive Homeopathy, Holistic Care" />
            </Helmet>

            <Box component="header" textAlign="center" my={{ xs: 3, md: 5 }}>
                <Typography variant="h3" component="h1" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                    Expert Homeopathic Treatment For Liver Conditions At Dr. Vandna Kanani’s Clinic
                </Typography>
            </Box>
            <Grid container spacing={4} component="article">
                <Grid item xs={12}>
                    <Box
                        sx={{
                            width: '100%',
                            paddingTop: '53.23%', // 330/620 * 100 = 53.23% for maintaining 620:330 aspect ratio
                            position: 'relative',
                            backgroundColor: '#fff',
                            borderRadius: 2,
                            overflow: 'hidden',
                        }}
                    >
                        <Avatar
                            variant="square"
                            src={blog4}
                            alt="A depiction of liver care at Dr. Vandna Kanani’s clinic"
                            sx={{ 
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover', 
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box mt={{ xs: 3, md: 5 }}>
                        <Typography variant="body1" paragraph sx={{ color: '#555', lineHeight: 1.7 }}>
                            At Dr. Vandna Kanani’s homeopathy clinic, we specialize in providing expert care for various liver conditions using a holistic and personalized approach...
                        </Typography>
                        <Section
                            title="Understanding Liver Conditions"
                            content="The liver is a vital organ responsible for numerous essential functions, including detoxification, protein synthesis, and the production of biochemicals necessary for digestion..."
                        />
                        <Section
                            title="The Predictive Homeopathy Approach"
                            content="Predictive homeopathy, a specialized practice mastered by Dr. Vandna Kanani, involves a comprehensive assessment of a patient's health..."
                        />
                        <Section
                            title="Holistic Treatment Plans for Liver Health"
                            content={
                                <List>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Homeopathic Remedies:</strong>} 
                                            secondary="Dr. Kanani prescribes specific homeopathic remedies based on a detailed evaluation of the patient’s symptoms and overall health..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Diet and Lifestyle Modifications:</strong>} 
                                            secondary="Proper diet and lifestyle choices are crucial for managing liver health. Dr. Kanani provides personalized dietary guidelines..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Detoxification Support:</strong>} 
                                            secondary="For patients with liver conditions, detoxification support is often a key component of treatment. Dr. Kanani may suggest natural detox methods..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Ongoing Monitoring and Support:</strong>} 
                                            secondary="Chronic liver conditions require continuous care. Dr. Kanani offers regular follow-up consultations to monitor liver health..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                </List>
                            }
                        />
                        <Section
                            title="Benefits of Homeopathic Treatment for Liver Conditions"
                            content={
                                <List>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Natural and Gentle:</strong>} 
                                            secondary="Homeopathic remedies are derived from natural sources and are free from harmful side effects..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Holistic Approach:</strong>} 
                                            secondary="Homeopathy considers the patient as a whole, addressing physical, emotional, and mental aspects..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Individualized Care:</strong>} 
                                            secondary="Treatments are customized to meet the specific needs of each patient, ensuring effective and personalized care..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Long-term Wellness:</strong>} 
                                            secondary="By addressing the root causes of liver issues, homeopathy provides a pathway to long-term health and prevents the recurrence of liver problems..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                </List>
                            }
                        />
                        <Section
                            title="Dr. Vandna Kanani: A Trusted Practitioner"
                            content="Dr. Vandna Kanani is renowned for her expertise in predictive homeopathy and her compassionate approach to patient care..."
                        />
                        <Section
                            title="Conclusion"
                            content="Liver conditions require a thoughtful and comprehensive treatment approach. At Dr. Vandna Kanani’s clinic, patients receive expert care that goes beyond symptom management..."
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

// Reusable Section Component
const Section = ({ title, content }) => (
    <Box mb={{ xs: 3, md: 4 }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
            {title}
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: '#666', lineHeight: 1.8 }}>
            {content}
        </Typography>
    </Box>
);

export default Blog4;
