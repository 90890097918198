import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import useAuth from "../../../Hooks/useAuth";
import logo from "../../../Images/icon-header-light.png";
import "./Header.css";

const Header = () => {
  const { user, logout } = useAuth();

  return (
    <div className="head-bg">
      <Navbar className="navbar" collapseOnSelect expand="lg">
        <Container className="container-head">
          <Navbar.Brand href="/home">
            <img src={logo} alt="logo" width="179px" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto align-items-center">
              <Link to="/home" className="list-item text-decoration-none">
                Home
              </Link>
              <Link to="/about" className="list-item text-decoration-none">
                About
              </Link>
              <a
                href="https://drvandnahomeopathy.com/blogs/"
                className="list-item text-decoration-none"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blogs
              </a>
              <NavDropdown
                title="Treatments"
                id="basic-nav-dropdown"
                className="nav-dropdown-title list-item text-decoration-none"
              >
                <NavDropdown.Item
                  as={Link}
                  to="/blog1"
                  className="dropdown-item"
                >
Respiratory Diseases              </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/blog2"
                  className="dropdown-item"
                >
Kidney Diseases                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/blog3"
                  className="dropdown-item"
                >
Skin Diseases                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/blog4"
                  className="dropdown-item"
                >
Liver Conditions                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/blog5"
                  className="dropdown-item"
                >
Diabetes                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/blog6"
                  className="dropdown-item"
                >
Migraine                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/blog7"
                  className="dropdown-item"
                >
Rheumatoid Arthritis                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/blog8"
                  className="dropdown-item"
                >
Piles                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/blog9"
                  className="dropdown-item"
                >
Knee Pain                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/blog10"
                  className="dropdown-item"
                >
Body Pain                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/blog11"
                  className="dropdown-item"
                >
Headaches                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/blog12"
                  className="dropdown-item"
                >
Joint Pain And Stiffness                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/blog13"
                  className="dropdown-item"
                >
Hyperpigmentation                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/blog14"
                  className="dropdown-item"
                >
Stammering                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/blog15"
                  className="dropdown-item"
                >
Conjunctivitis                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/blog16"
                  className="dropdown-item"
                >
Overcoming Fear                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/blog17"
                  className="dropdown-item"
                >
Vomiting                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/blog18"
                  className="dropdown-item"
                >
High Blood Pressure                </NavDropdown.Item>
              </NavDropdown>
              <Link to="/drvandna" className="list-item text-decoration-none">
                Dr. Vandna
              </Link>
              <Link
                to="/contact"
                className="list-item text-decoration-none"
                style={{ marginRight: "5px" }}
              >
                Contact
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
