import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Achivement.css";

const Achivement = () => {
  return (
    <section className="achivement-wrap text-white">
      <Container style={{marginBottom:"-80px"}}>
        <Row className="align-items-center">
          <Col md={6} lg={6} sm={12}>
            <div className="section-title">
              <h1>Why Choose Dr. Vandna's Homeopathy?</h1>
            </div>
            <div className="achivement-txt mx-5">
              <p>
                <strong>Holistic Health:</strong> We treat the whole person,
                addressing both physical and emotional aspects of health.
              </p>
              <p>
                <strong>Personalized Care:</strong> Each treatment plan is
                tailored to the individual's unique needs.
              </p>
              <p>
                <strong>Root Cause Healing:</strong> We focus on identifying and
                treating the root causes of health issues.
              </p>
              <p>
                <strong>Compassionate Care:</strong> We provide empathetic and
                respectful care in a supportive environment.
              </p>
              <p>
                <strong>Innovative Treatments:</strong> We integrate the latest
                advancements in homeopathy and medical research into our
                practice.
              </p>
              {/* <div className="more-tool">
                <Link to="/login">
                  <button className="theme-btn btn-fill">Appoinment</button>
                </Link>
                <a
                  href="https://www.youtube.com/watch?v=7HhdN9_MgE8"
                  className="watchBtn"
                >
                  <button className="theme-btn btn-unfill">
                    <FontAwesomeIcon className="play-btn" icon={faPlayCircle} />
                    <span>Watch Vedio</span>
                  </button>
                </a>
              </div> */}
            </div>
          </Col>
          <Col md={6} lg={6} sm={12}>
            <Row className="achivement-funfact text-white">
              <Col sm={6} className="text-center">
                <div className="single-funfact">
                  <span>10k +</span>
                  <p>Patients</p>
                </div>
              </Col>
              <Col sm={6} className="text-center">
                <div className="single-funfact">
                  <span>25 +</span>
                  <p>Countries Reached</p>
                </div>
              </Col>
              <Col sm={6} className="text-center">
                <div className="single-funfact">
                  <span>5 +</span>
                  <p>Awards</p>
                </div>
              </Col>
              <Col sm={6} className="text-center">
                <div className="single-funfact">
                  <span>7+</span>
                  <p>Years Experience</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Achivement;
