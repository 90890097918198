import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import expertDentist from "../../../Images/experienceddentist.png";
import "./Dentist.css";

const Dentist = () => {
  return (
    <section className="expert-dentist">
      <Container style={{ marginTop: "-140px"}}>
        <Row>
          <Col sm={12}>
            <div className="section-title text-center">
              <h1>Our Vision</h1>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col lg={6}>
            <p className="m-auto content-inner" style={{ width: "85%" }}>
              At Dr. Vandna Kanani's clinic, our vision is to revolutionize
              healthcare through the principles of predictive homeopathy,
              focusing on holistic, personalized, and root-cause-oriented
              treatments. We aim to be a beacon of hope and healing for those
              seeking natural and effective solutions to their health
              challenges.
            </p>
            <br />
            <p className="m-auto content-inner" style={{ width: "85%" }}>
              <strong>Holistic Health:</strong> We believe in treating the whole
              person—mind, body, and spirit. Our approach goes beyond merely
              alleviating symptoms; we strive to enhance overall well-being by
              addressing the underlying causes of health issues.
            </p>
            <br />
            <p className="m-auto content-inner" style={{ width: "85%" }}>
              <strong>Personalized Care:</strong> Every individual is unique,
              and so is their path to health. We are committed to providing
              customized treatment plans tailored to each patient's specific
              needs, ensuring the best possible outcomes.
            </p>
            <br />
            <p className="m-auto content-inner" style={{ width: "85%" }}>
              <strong>Compassionate Care:</strong> At the heart of our practice
              is a deep commitment to compassionate care. We treat each patient
              with empathy, respect, and understanding, fostering a supportive
              and healing environment.
            </p>
          </Col>
          <Col lg={6}>
            <p className="m-auto content-inner" style={{ width: "85%" }}>
              <strong>Education and Empowerment:</strong> We are dedicated to
              educating our patients about their health and empowering them to
              take an active role in their wellness journey. Knowledge is a
              powerful tool in achieving and maintaining health.
            </p>
            <br />
            <p className="m-auto content-inner" style={{ width: "85%" }}>
              <strong>Innovative Treatments:</strong> We continuously seek to
              integrate the latest advancements in homeopathy and medical
              research into our practice. Our goal is to provide cutting-edge,
              evidence-based treatments that are safe, natural, and effective.
            </p>
            <br />
            <p className="m-auto content-inner" style={{ width: "85%" }}>
              <strong>Root Cause Healing:</strong> Our focus is on identifying
              and addressing the root causes of ailments, rather than just
              masking symptoms. By understanding the intricate connections
              between various aspects of health, we aim to promote true and
              lasting healing.
            </p>
            <br />
            <p className="m-auto content-inner" style={{ width: "85%" }}>
              Our vision is to create a world where individuals can achieve
              optimal health and wellness through the power of predictive
              homeopathy. We are dedicated to making this vision a reality, one
              patient at a time. Thank you for being a part of our journey.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Dentist;
