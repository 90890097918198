import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Content.css";

const Content = () => {
  return (
    <section className="about-content-sec">
      <Container>
        <Row>
          <Col sm={12}>
            <div className="section-title text-center">
              <h1>How We Started Predictive Homeopathy at our clinic</h1>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col lg={6}>
            <p className="m-auto content-inner" style={{ width: "85%" }}>
              The journey of predictive homeopathy at our clinic began with a
              deep-seated passion for holistic healing and a commitment to
              uncovering the root causes of health issues. As a practitioner, I,
              Dr. Vandna Kanani, have always been fascinated by the body's
              intrinsic ability to heal itself when given the right support.
              This curiosity led me to explore various approaches to homeopathy,
              ultimately discovering the transformative potential of predictive
              homeopathy.
            </p>
            <br />
            <p className="m-auto content-inner" style={{ width: "85%" }}>
              Predictive homeopathy is not just about treating symptoms; it’s
              about understanding the complex interplay between the mind, body,
              and environment. This approach emphasizes identifying and
              addressing the underlying factors that contribute to health
              conditions. My training and experience in this specialized field
              have equipped me with the skills to offer personalized, effective
              treatment plans that promote long-term well-being.
            </p>
          </Col>
          <Col lg={6}>
            {/* <div className="expertDentist-txt mt-5 mt-lg-0"> */}
            <p className="m-auto content-inner" style={{ width: "85%" }}>
              Our clinic was founded with the mission to provide comprehensive
              and compassionate care. We started with a small but dedicated
              team, driven by the belief that every patient deserves a treatment
              plan tailored to their unique needs. Through continuous learning,
              research, and patient feedback, we have refined our methods and
              expanded our expertise in predictive homeopathy.
            </p>
            <br />
            <p className="m-auto content-inner" style={{ width: "85%" }}>
              Today, we are proud to offer holistic care that combines the
              principles of traditional homeopathy with the insights gained from
              modern medical research. Our journey has been one of growth and
              dedication, and we remain committed to helping our patients
              achieve optimal health through natural and personalized
              treatments.
            </p>
            <br />
            <p className="m-auto content-inner" style={{ width: "85%" }}>
              Thank you for being a part of our story. We look forward to
              continuing this journey of healing and wellness with you.
            </p>
            {/* </div> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Content;
