import React from 'react';
import { Container, Grid, Typography, Box, Avatar, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import blog7 from '../../../src/Images/blog7.jpg';
import { Helmet } from 'react-helmet';

const Blog7 = () => {
    return (
        <Container maxWidth="lg" sx={{ backgroundColor: '#ffffff', padding: 4, borderRadius: 2 }}>
            <Helmet>
                <title>Homeopathic Treatment For Rheumatoid Arthritis At Dr. Vandna Kanani’s Clinic</title>
                <meta name="description" content="Discover effective homeopathic treatments for Rheumatoid Arthritis at Dr. Vandna Kanani's clinic. Learn how predictive homeopathy can improve joint health and overall well-being." />
                <meta name="keywords" content="Rheumatoid Arthritis, Homeopathy, Dr. Vandna Kanani, Predictive Homeopathy, Holistic Care" />
            </Helmet>

            <Box component="header" textAlign="center" my={{ xs: 3, md: 5 }}>
                <Typography variant="h3" component="h1" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                    Homeopathic Treatment For Rheumatoid Arthritis At Dr. Vandna Kanani’s Clinic
                </Typography>
            </Box>
            <Grid container spacing={4} component="article">
                <Grid item xs={12}>
                    <Box
                        sx={{
                            width: '100%',
                            paddingTop: '53.23%', // 330/620 * 100 = 53.23% for maintaining 620:330 aspect ratio
                            position: 'relative',
                            backgroundColor: '#fff',
                            borderRadius: 2,
                            overflow: 'hidden',
                        }}
                    >
                        <Avatar
                            variant="square"
                            src={blog7}
                            alt="Rheumatoid Arthritis care at Dr. Vandna Kanani’s clinic"
                            sx={{ 
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover', 
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box mt={{ xs: 3, md: 5 }}>
                        <Typography variant="body1" paragraph sx={{ color: '#555', lineHeight: 1.7 }}>
                            Rheumatoid Arthritis (RA) is a chronic autoimmune condition characterized by inflammation in the joints, leading to pain, swelling, stiffness, and potential joint damage. At Dr. Vandna Kanani’s clinic, we utilize predictive homeopathy to provide a holistic and personalized approach to treating RA, addressing the condition's underlying causes and promoting overall well-being...
                        </Typography>
                        <Section
                            title="Understanding Rheumatoid Arthritis"
                            content="Rheumatoid Arthritis is an autoimmune disorder in which the immune system mistakenly attacks the synovium—the lining of the membranes that surround the joints..."
                        />
                        <Section
                            title="The Predictive Homeopathy Approach"
                            content="Dr. Vandna Kanani's approach to treating RA with predictive homeopathy involves a thorough understanding of the patient’s health history, lifestyle, and genetic predispositions..."
                        />
                        <Section
                            title="Personalized Treatment Plans for Rheumatoid Arthritis"
                            content={
                                <List>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Custom Homeopathic Remedies:</strong>} 
                                            secondary="Dr. Kanani prescribes individualized homeopathic remedies tailored to each patient’s unique symptoms and overall health profile..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Diet and Lifestyle Guidance:</strong>} 
                                            secondary="Nutrition and lifestyle play crucial roles in managing RA. Dr. Kanani offers dietary recommendations to reduce inflammation..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Stress Management Techniques:</strong>} 
                                            secondary="Chronic stress can worsen autoimmune conditions like RA. Dr. Kanani provides patients with strategies to manage stress..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Patient Education and Empowerment:</strong>} 
                                            secondary="Dr. Kanani believes in educating her patients about RA and the importance of self-care. She empowers them with the knowledge and tools needed..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Regular Monitoring and Support:</strong>} 
                                            secondary="Ongoing care is vital in managing a chronic condition like RA. Dr. Kanani offers regular follow-up consultations to monitor patients' progress..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                </List>
                            }
                        />
                        <Section
                            title="Benefits of Homeopathic Treatment for Rheumatoid Arthritis"
                            content={
                                <List>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Natural and Gentle:</strong>} 
                                            secondary="Homeopathic remedies are derived from natural substances and are known for their gentle, non-toxic nature..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Holistic Approach:</strong>} 
                                            secondary="Homeopathy treats the whole person, considering physical, emotional, and psychological aspects..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Individualized Care:</strong>} 
                                            secondary="Every patient receives a unique treatment plan tailored to their specific symptoms and health needs..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Focus on Prevention:</strong>} 
                                            secondary="By addressing the root causes and promoting a healthy lifestyle, homeopathy helps prevent disease progression..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                </List>
                            }
                        />
                        <Section
                            title="Dr. Vandna Kanani: A Trusted Expert in Homeopathy"
                            content="Dr. Vandna Kanani is a renowned homeopathic practitioner known for her compassionate care and expertise in predictive homeopathy..."
                        />
                        <Section
                            title="Conclusion"
                            content="At Dr. Vandna Kanani’s clinic, patients with Rheumatoid Arthritis receive comprehensive, personalized care designed to address the root causes of their condition..."
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

// Reusable Section Component
const Section = ({ title, content }) => (
    <Box mb={{ xs: 3, md: 4 }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
            {title}
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: '#666', lineHeight: 1.8 }}>
            {content}
        </Typography>
    </Box>
);

export default Blog7;
