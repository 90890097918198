import React from 'react';
import { Container, Grid, Typography, Box, Avatar, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import blog8 from '../../../src/Images/blog8.jpg';
import { Helmet } from 'react-helmet';

const Blog8 = () => {
    return (
        <Container maxWidth="lg" sx={{ backgroundColor: '#ffffff', padding: 4, borderRadius: 2 }}>
            <Helmet>
                <title>Effective Homeopathic Treatment For Piles At Dr. Vandna Kanani’s Clinic</title>
                <meta name="description" content="Explore effective homeopathic treatments for piles at Dr. Vandna Kanani's clinic. Learn how predictive homeopathy can alleviate symptoms and promote overall well-being." />
                <meta name="keywords" content="Piles, Hemorrhoids, Homeopathy, Dr. Vandna Kanani, Predictive Homeopathy, Holistic Care" />
            </Helmet>

            <Box component="header" textAlign="center" my={{ xs: 3, md: 5 }}>
                <Typography variant="h3" component="h1" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                    Effective Homeopathic Treatment For Piles At Dr. Vandna Kanani’s Clinic
                </Typography>
            </Box>
            <Grid container spacing={4} component="article">
                <Grid item xs={12}>
                    <Box
                        sx={{
                            width: '100%',
                            paddingTop: '53.23%', // 330/620 * 100 = 53.23% for maintaining 620:330 aspect ratio
                            position: 'relative',
                            backgroundColor: '#fff',
                            borderRadius: 2,
                            overflow: 'hidden',
                        }}
                    >
                        <Avatar
                            variant="square"
                            src={blog8}
                            alt="Piles treatment at Dr. Vandna Kanani’s clinic"
                            sx={{ 
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover', 
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box mt={{ xs: 3, md: 5 }}>
                        <Typography variant="body1" paragraph sx={{ color: '#555', lineHeight: 1.7 }}>
                            Piles, also known as hemorrhoids, are swollen and inflamed veins in the rectum and anus that can cause discomfort, pain, and bleeding. At Dr. Vandna Kanani’s clinic, we offer specialized homeopathic treatments for piles that focus on identifying and addressing the root causes of the condition...
                        </Typography>
                        <Section
                            title="Understanding Piles (Hemorrhoids)"
                            content="Piles are categorized into two main types: internal and external. Internal hemorrhoids develop inside the rectum, while external hemorrhoids occur under the skin around the anus..."
                        />
                        <Section
                            title="The Predictive Homeopathy Approach"
                            content="At Dr. Vandna Kanani’s clinic, we employ predictive homeopathy to treat piles. This approach involves a thorough analysis of each patient's health history, lifestyle, and contributing factors..."
                        />
                        <Section
                            title="Personalized Treatment Plans for Piles"
                            content={
                                <List>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Custom Homeopathic Remedies:</strong>} 
                                            secondary="Dr. Kanani prescribes individualized homeopathic remedies based on the specific symptoms and health profile of each patient..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Dietary and Lifestyle Modifications:</strong>} 
                                            secondary="A significant part of the treatment involves advising patients on dietary and lifestyle changes. Dr. Kanani recommends a high-fiber diet..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Stress Management:</strong>} 
                                            secondary="Stress can exacerbate symptoms of piles. Dr. Kanani provides guidance on stress management techniques, such as mindfulness, meditation..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Patient Education and Support:</strong>} 
                                            secondary="Educating patients about piles, including how to prevent flare-ups and manage symptoms, is a key component of our treatment approach..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Regular Monitoring and Follow-up:</strong>} 
                                            secondary="To ensure the effectiveness of the treatment, Dr. Kanani schedules regular follow-up consultations. This allows for monitoring of the patient's progress..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                </List>
                            }
                        />
                        <Section
                            title="Benefits of Homeopathic Treatment for Piles"
                            content={
                                <List>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Natural and Non-invasive:</strong>} 
                                            secondary="Homeopathic remedies are derived from natural sources and are known for their safety and lack of side effects..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Holistic Care:</strong>} 
                                            secondary="Homeopathy treats the individual as a whole, addressing both physical symptoms and emotional well-being..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Personalized Treatment:</strong>} 
                                            secondary="Each treatment plan is tailored to the unique needs of the patient, ensuring a more effective and individualized approach to managing piles..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Focus on Prevention:</strong>} 
                                            secondary="By addressing the root causes and promoting healthy lifestyle changes, homeopathy helps prevent future occurrences and complications of piles..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                </List>
                            }
                        />
                        <Section
                            title="Dr. Vandna Kanani: A Trusted Homeopathic Practitioner"
                            content="Dr. Vandna Kanani is a respected homeopathic practitioner with extensive experience in treating a wide range of conditions, including piles..."
                        />
                        <Section
                            title="Conclusion"
                            content="At Dr. Vandna Kanani’s clinic, we provide comprehensive, personalized homeopathic treatment for piles that focuses on the root causes of the condition..."
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

// Reusable Section Component
const Section = ({ title, content }) => (
    <Box mb={{ xs: 3, md: 4 }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
            {title}
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: '#666', lineHeight: 1.8 }}>
            {content}
        </Typography>
    </Box>
);

export default Blog8;
