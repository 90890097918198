import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Homeopathy from "../../../Images/homeopathy.png";
import "./Banner.css";
const Banner = () => {
  return (
    <section className="banner-all text-white">
      <Container>
        <Row className="align-items-center">
          <Col md={6} lg={6} sm={12}>
            <div className="section-title">
              <h1>About Us</h1>
            </div>
            <div className="breadcrumb-nav" style={{marginTop:"-30px"}}>
              <a href="/" className="text-decoration-none text-white">
                Home Page
              </a>
              <span href="/" className="text-decoration-none text-white ml-2">
                About Us
              </span>
            </div>
            <br />
            <p>
              Holistic and personalized approach to healthcare that focuses on
              uncovering and addressing the root causes of health issues. Our
              mission is to provide comprehensive and compassionate care that
              goes beyond merely alleviating symptoms, aiming instead to enhance
              overall well-being.
            </p>
            <p>
              <strong>Dr. Vandna Kanani: </strong>
              Dr. Vandna Kanani is a highly experienced practitioner in
              predictive homeopathy. With years of dedicated practice, she has
              successfully treated a wide range of conditions. Her commitment to
              continuous learning and patient-centered care has earned her a
              reputation for excellence in the field of homeopathy.
            </p>
          </Col>
          <Col md={6} lg={6} sm={12}>
            <div className="hero-slide-right text-start">
              <div className="banner-animate">
                <img src={Homeopathy} alt="" className="img-fluid" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
