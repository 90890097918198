import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Appoinment.css";

const Appoinment = () => {
  const [detail, setDetails] = useState({
    name: "",
    contact: "",
    email: "",
    message: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const formId = "1FAIpQLSfA8knft55Qb1gsCxK8MI2VpJQmkMV6ZQAVkWuuo4Vz0NUXiA";
    const entryName = "entry.2005620554";
    const entryEmail = "entry.1045781291";
    const entryContact = "entry.1166974658";
    const entryMessage = "entry.839337160";

    const url = `https://docs.google.com/forms/d/e/${formId}/formResponse?${entryName}=${encodeURIComponent(
      detail.name
    )}&${entryEmail}=${encodeURIComponent(detail.email)}&${entryContact}=${encodeURIComponent(
      detail.contact
    )}&${entryMessage}=${encodeURIComponent(detail.message)}`;

    fetch(url, {
      method: "POST",
      mode: "no-cors",
    })
      .then(() => {
        alert("Form submitted successfully!");
        // Reset the form after submission
        setDetails({ name: "", contact: "", email: "", message: "" });
      })
      .catch((error) => {
        alert("Error submitting form");
        console.error("Error:", error);
      });
  };

  const onchange = (e) => {
    setDetails({ ...detail, [e.target.name]: e.target.value });
  };

  return (
    <section className="appoinment-wrapper">
      <Container>
        <Row>
          <Col sm={12} md={12}>
            <div className="section-title">
              <h1 className="mt-5">Request Appointment</h1>
            </div>
            <div className="appoinment-form">
              <form className="row" onSubmit={handleSubmit}>
                <Col md={12} lg={12}>
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    id="name"
                    value={detail.name}
                    onChange={onchange}
                    required
                  />
                </Col>
                <Col md={12} lg={12}>
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    id="email"
                    value={detail.email}
                    onChange={onchange}
                    required
                  />
                </Col>
                <Col md={12} lg={12}>
                  <input
                    type="phone"
                    placeholder="Phone"
                    name="contact"
                    id="contact"
                    value={detail.contact}
                    onChange={onchange}
                    required
                  />
                </Col>
                <Col md={12} lg={12}>
                  <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="10"
                    placeholder="Message"
                    value={detail.message}
                    onChange={onchange}
                  ></textarea>
                </Col>
                <button
                  className="theme-btn btn-fill form-btn mt-5"
                  type="submit"
                  disabled={detail.name.length < 3}
                >
                  Submit
                </button>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Appoinment;
