import React from 'react';
import { Container, Grid, Typography, Box, Avatar, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import blog2 from '../../../src/Images/blog2.jpg';
import { Helmet } from 'react-helmet';

const Blog2 = () => {
    return (
        <Container maxWidth="lg" sx={{ backgroundColor: '#ffffff', padding: 4, borderRadius: 2 }}>
            <Helmet>
                <title>Best Homeopathic Treatment For Kidney Diseases At Dr. Vandna Kanani’s Clinic</title>
                <meta name="description" content="Discover the best homeopathic treatments for kidney diseases at Dr. Vandna Kanani's clinic. Learn how predictive homeopathy can improve kidney function and enhance your quality of life." />
                <meta name="keywords" content="Kidney Diseases, Homeopathy, Dr. Vandna Kanani, Predictive Homeopathy, Holistic Care" />
            </Helmet>

            <Box component="header" textAlign="center" my={{ xs: 3, md: 5 }}>
                <Typography variant="h3" component="h1" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                    Best Homeopathic Treatment For Kidney Diseases At Dr. Vandna Kanani’s Clinic
                </Typography>
            </Box>
            <Grid container spacing={4} component="article">
                <Grid item xs={12}>
                    <Box
                        sx={{
                            width: '100%',
                            paddingTop: '53.23%', // 330/620 * 100 = 53.23% for maintaining 620:330 aspect ratio
                            position: 'relative',
                            backgroundColor: '#fff',
                            borderRadius: 2,
                            overflow: 'hidden',
                        }}
                    >
                        <Avatar
                            variant="square"
                            src={blog2}
                            alt="A depiction of kidney care at Dr. Vandna Kanani’s clinic"
                            sx={{ 
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover', 
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box mt={{ xs: 3, md: 5 }}>
                        <Typography variant="body1" paragraph sx={{ color: '#555', lineHeight: 1.7 }}>
                            At Dr. Vandna Kanani’s homeopathy clinic, we specialize in treating kidney diseases using a holistic and personalized approach...
                        </Typography>
                        <Section
                            title="Understanding Kidney Diseases"
                            content="Kidney diseases encompass a range of conditions that affect the kidneys' ability to filter waste and excess fluids from the blood. Common kidney disorders include chronic kidney disease (CKD), kidney stones, glomerulonephritis, and urinary tract infections (UTIs)..."
                        />
                        <Section
                            title="The Predictive Homeopathy Approach"
                            content="Dr. Vandna Kanani is an expert in predictive homeopathy, an approach that goes beyond treating symptoms to understand and address the root causes of health issues..."
                        />
                        <Section
                            title="Holistic Treatment Plans"
                            content={
                                <List>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Homeopathic Remedies:</strong>} 
                                            secondary="Dr. Kanani selects specific remedies based on the patient's symptoms, overall health, and individual constitution..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Dietary and Lifestyle Guidance:</strong>} 
                                            secondary="A crucial component of managing kidney diseases is diet and lifestyle. Dr. Kanani provides personalized dietary recommendations..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Preventive Measures:</strong>} 
                                            secondary="Predictive homeopathy emphasizes prevention. By identifying risk factors and early signs of kidney disease, Dr. Kanani helps patients take proactive steps..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Regular Monitoring and Support:</strong>} 
                                            secondary="Chronic kidney diseases require ongoing care and monitoring. Dr. Kanani offers regular follow-up consultations..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                </List>
                            }
                        />
                        <Section
                            title="Benefits of Homeopathic Treatment for Kidney Diseases"
                            content={
                                <List>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Natural and Safe:</strong>} 
                                            secondary="Homeopathic remedies are gentle and free from side effects, making them suitable for patients of all ages..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Holistic Care:</strong>} 
                                            secondary="Homeopathy treats the individual as a whole, addressing physical, emotional, and mental aspects of health..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Custom-Tailored Treatment:</strong>} 
                                            secondary="Each treatment plan is customized to meet the unique needs of the patient, ensuring the most effective care..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Long-Term Wellness:</strong>} 
                                            secondary="By focusing on the root causes and promoting the body's self-healing abilities, homeopathy can help patients achieve long-term wellness..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                </List>
                            }
                        />
                        <Section
                            title="Dr. Vandna Kanani: A Trusted Practitioner"
                            content="Dr. Vandna Kanani is renowned for her expertise in predictive homeopathy and her compassionate approach to patient care..."
                        />
                        <Section
                            title="Conclusion"
                            content="Kidney diseases require careful management and a comprehensive treatment approach. At Dr. Vandna Kanani’s clinic, patients receive expert care that not only addresses their immediate health concerns but also promotes overall well-being..."
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

// Reusable Section Component
const Section = ({ title, content }) => (
    <Box mb={{ xs: 3, md: 4 }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
            {title}
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: '#666', lineHeight: 1.8 }}>
            {content}
        </Typography>
    </Box>
);

export default Blog2;
