import React from 'react';
import { Container, Grid, Typography, Box, Avatar, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import blog3 from '../../../src/Images/blog3.jpg';
import { Helmet } from 'react-helmet';

const Blog3 = () => {
    return (
        <Container maxWidth="lg" sx={{ backgroundColor: '#ffffff', padding: 4, borderRadius: 2 }}>
            <Helmet>
                <title>Best Homeopathic Treatment For Skin Diseases At Dr. Vandna Kanani’s Clinic</title>
                <meta name="description" content="Discover the best homeopathic treatments for skin diseases at Dr. Vandna Kanani's clinic. Learn how predictive homeopathy can improve your skin health and overall well-being." />
                <meta name="keywords" content="Skin Diseases, Homeopathy, Dr. Vandna Kanani, Predictive Homeopathy, Holistic Care" />
            </Helmet>

            <Box component="header" textAlign="center" my={{ xs: 3, md: 5 }}>
                <Typography variant="h3" component="h1" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                    Best Homeopathic Treatment For Skin Diseases At Dr. Vandna Kanani’s Clinic
                </Typography>
            </Box>
            <Grid container spacing={4} component="article">
                <Grid item xs={12}>
                    <Box
                        sx={{
                            width: '100%',
                            paddingTop: '53.23%', // 330/620 * 100 = 53.23% for maintaining 620:330 aspect ratio
                            position: 'relative',
                            backgroundColor: '#fff',
                            borderRadius: 2,
                            overflow: 'hidden',
                        }}
                    >
                        <Avatar
                            variant="square"
                            src={blog3}
                            alt="A depiction of skin care at Dr. Vandna Kanani’s clinic"
                            sx={{ 
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover', 
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box mt={{ xs: 3, md: 5 }}>
                        <Typography variant="body1" paragraph sx={{ color: '#555', lineHeight: 1.7 }}>
                            Dr. Vandna Kanani’s homeopathy clinic offers expert care for a wide range of skin diseases using a holistic and personalized approach...
                        </Typography>
                        <Section
                            title="Understanding Skin Diseases"
                            content="Skin diseases encompass a variety of conditions that affect the skin, including eczema, psoriasis, acne, rosacea, dermatitis, fungal infections, and more..."
                        />
                        <Section
                            title="The Predictive Homeopathy Approach"
                            content="Predictive homeopathy is a specialized practice that Dr. Kanani has mastered through years of experience. This approach involves a comprehensive assessment of the patient's health history..."
                        />
                        <Section
                            title="Holistic Treatment Plans"
                            content={
                                <List>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Homeopathic Remedies:</strong>} 
                                            secondary="Dr. Kanani selects homeopathic remedies based on a detailed understanding of the patient's unique symptoms, overall health, and constitution..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Diet and Lifestyle Recommendations:</strong>} 
                                            secondary="Proper nutrition and lifestyle choices play a crucial role in managing skin conditions. Dr. Kanani provides personalized advice on diet and lifestyle modifications..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Holistic Skin Care:</strong>} 
                                            secondary="Beyond medication, Dr. Kanani advises on holistic skin care practices, including natural skin care products, safe sun exposure, and techniques to manage stress..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Ongoing Monitoring and Support:</strong>} 
                                            secondary="Skin conditions can be chronic and require ongoing care. Dr. Kanani offers regular follow-up consultations to monitor progress..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                </List>
                            }
                        />
                        <Section
                            title="Benefits of Homeopathic Treatment for Skin Diseases"
                            content={
                                <List>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Natural and Safe:</strong>} 
                                            secondary="Homeopathic remedies are derived from natural sources and are safe for all ages, with no side effects..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Holistic Healing:</strong>} 
                                            secondary="Homeopathy treats the individual as a whole, considering physical, emotional, and mental aspects, which is crucial for managing skin conditions..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Customized Care:</strong>} 
                                            secondary="Each treatment is tailored to the individual’s specific needs, ensuring effective and personalized care..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Long-term Solutions:</strong>} 
                                            secondary="By addressing the root causes of skin issues, homeopathy provides long-term relief and helps prevent recurrence..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                </List>
                            }
                        />
                        <Section
                            title="Dr. Vandna Kanani: A Trusted Practitioner"
                            content="Dr. Vandna Kanani is a respected practitioner known for her expertise in predictive homeopathy and her compassionate, patient-centered approach..."
                        />
                        <Section
                            title="Conclusion"
                            content="Skin diseases require a thoughtful and comprehensive approach to treatment. At Dr. Vandna Kanani’s clinic, patients receive expert care that goes beyond symptom management..."
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

// Reusable Section Component
const Section = ({ title, content }) => (
    <Box mb={{ xs: 3, md: 4 }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
            {title}
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: '#666', lineHeight: 1.8 }}>
            {content}
        </Typography>
    </Box>
);

export default Blog3;
