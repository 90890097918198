import React from 'react';
import { Container, Grid, Typography, Box, Avatar, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import approved from '../../../src/Images/Respiratory.jpg';
import { Helmet } from 'react-helmet';

const Blog1 = () => {
    return (
        <Container maxWidth="lg" sx={{ backgroundColor: '#ffffff', padding: 4, borderRadius: 2 }}>
            <Helmet>
                <title>Respiratory Diseases - Dr. Vandna Kanani's Homeopathy Clinic</title>
                <meta name="description" content="Learn about respiratory diseases and the holistic treatment approach at Dr. Vandna Kanani's homeopathy clinic. Discover how predictive homeopathy can help you breathe easier and live healthier." />
                <meta name="keywords" content="Respiratory Diseases, Homeopathy, Dr. Vandna Kanani, Predictive Homeopathy, Holistic Care" />
            </Helmet>
            
            <Box component="header" textAlign="center" my={{ xs: 3, md: 5 }}>
                <Typography variant="h3" component="h1" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                    Understanding Respiratory Diseases and Holistic Homeopathic Treatments
                </Typography>
            </Box>
            <Grid container spacing={4} component="article">
                <Grid item xs={12}>
                    <Box
                        sx={{
                            width: '100%',
                            paddingTop: '53.23%', // 330/620 * 100 = 53.23% for maintaining 620:330 aspect ratio
                            position: 'relative',
                            backgroundColor: '#fff',
                            borderRadius: 2,
                            overflow: 'hidden',
                        }}
                    >
                        <Avatar
                            variant="square"
                            src={approved}
                            alt="A depiction of respiratory care at Dr. Vandna Kanani’s clinic"
                            sx={{ 
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover', 
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box mt={{ xs: 3, md: 5 }}>
                        <Typography variant="body1" paragraph sx={{ color: '#555', lineHeight: 1.7 }}>
                            At Dr. Vandna Kanani’s homeopathy clinic, we offer specialized and compassionate care for patients suffering from various respiratory diseases...
                        </Typography>
                        <Section
                            title="Understanding Respiratory Diseases"
                            content="Respiratory diseases encompass a range of conditions that affect the lungs and airways, including asthma, bronchitis, chronic obstructive pulmonary disease (COPD), allergies, and sinusitis..."
                        />
                        <Section
                            title="The Predictive Homeopathy Approach"
                            content="Predictive homeopathy is a specialized field that Dr. Kanani has mastered over years of practice. This approach involves a thorough evaluation of a patient's health history..."
                        />
                        <Section
                            title="Holistic Treatment Plans"
                            content={
                                <List>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Homeopathic Remedies:</strong>} 
                                            secondary="Carefully selected based on the patient's unique symptoms, constitution, and overall health..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Lifestyle and Dietary Recommendations:</strong>} 
                                            secondary="Dr. Kanani advises on lifestyle changes and dietary adjustments that can help manage and improve respiratory health..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Breathing Exercises and Techniques:</strong>} 
                                            secondary="To help manage symptoms like shortness of breath and to improve lung capacity, Dr. Kanani may recommend specific breathing exercises and relaxation techniques..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Ongoing Support and Monitoring:</strong>} 
                                            secondary="Chronic conditions require continuous monitoring and support. Dr. Kanani’s clinic provides regular follow-ups to track progress..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                </List>
                            }
                        />
                        <Section
                            title="The Benefits of Homeopathic Treatment for Respiratory Diseases"
                            content={
                                <List>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Safe and Natural:</strong>} 
                                            secondary="Homeopathic remedies are natural and safe, with no risk of harmful side effects..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Holistic Care:</strong>} 
                                            secondary="Rather than merely suppressing symptoms, homeopathy seeks to restore overall health..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Customized Treatment:</strong>} 
                                            secondary="Each patient receives a treatment plan tailored to their specific needs..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ alignItems: 'flex-start' }}>
                                        <ListItemIcon>
                                            <CheckCircleIcon sx={{ color: '#4caf50' }} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<strong>Long-term Relief:</strong>} 
                                            secondary="By strengthening the immune system and improving overall health, homeopathy can provide long-term relief..."
                                            secondaryTypographyProps={{ sx: { color: '#555' } }}
                                        />
                                    </ListItem>
                                </List>
                            }
                        />
                        <Section
                            title="A Trusted Practitioner: Dr. Vandna Kanani"
                            content="Dr. Vandna Kanani is a well-respected practitioner known for her expertise in predictive homeopathy and her empathetic approach to patient care..."
                        />
                        <Section
                            title="Conclusion"
                            content="For those struggling with respiratory diseases, finding a treatment that not only alleviates symptoms but also enhances overall well-being is crucial..."
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

// Reusable Section Component
const Section = ({ title, content }) => (
    <Box mb={{ xs: 3, md: 4 }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
            {title}
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: '#666', lineHeight: 1.8 }}>
            {content}
        </Typography>
    </Box>
);

export default Blog1;
