import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import "./Details.css";

const Details = () => {
  return (
    <>
      <section className="Dentist-details-sec">
        <Container>
          <Row>
            <Col md={7} lg={8}>
              <div className="single-Dentist-details">
                <h2>
                  Dr. Vandna <span>(BHMS)</span>
                </h2>
                <p>
                  <h6>Dr. Vandna Kanani:</h6>{" "}
                  <strong>
                    Expertise in Homeopathy I am Dr. Vandna Kanani, a
                    distinguished practitioner specializing in predictive
                    homeopathy, a revolutionary approach designed to identify
                    and address the root causes of health issues. With years of
                    experience and a profound understanding of homeopathy, I
                    have established myself as a leading figure in the field. My
                    expertise lies in providing personalized, holistic
                    treatments that not only alleviate symptoms but also enhance
                    overall well-being. My expertise in homeopathy is
                    characterized by a deep understanding of predictive
                    homeopathy, a patient-centric approach, and a commitment to
                    continuous learning and patient education. My ability to
                    treat a wide range of conditions, empathetic nature, and
                    dedication to holistic healing make me a highly respected
                    and sought-after practitioner. Under my care, patients can
                    expect not only relief from their ailments but also a
                    comprehensive improvement in their overall health and
                    well-being.
                  </strong>
                </p>
              </div>
              <div className="single-Dentist-details">
                <p>
                  <h6>Our Approach:</h6>
                  <strong>
                    At our clinic, we believe in treating the whole person—mind,
                    body, and spirit. Predictive homeopathy allows us to
                    identify and address the underlying factors that contribute
                    to health conditions, rather than just masking symptoms. Our
                    personalized treatment plans are tailored to each patient's
                    unique needs, ensuring the best possible outcomes.
                  </strong>
                </p>
              </div>
            </Col>
            <Col md={5} lg={4}>
              <div className="Dentist-profile text-center">
                <div
                  className="profile-img"
                  style={{ backgroundImage: `url(/drvandna.jpg)` }}
                ></div>
                <p>
                  Name: <strong>Dr. Vandna</strong>
                </p>
                <p>
                  Specialization: <strong>Homeopathy</strong>
                </p>
                <p>
                  Phone: <strong>+91 87806 42122</strong>
                </p>
                <div className="doctors-social">
                  <a href=".#">
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                  <a href=".#">
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                  <a href=".#">
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pb-5">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="achivement-img-bg"></div>
            </Col>
            <Col lg={6}>
              <div className="expertDentist-txt mt-5 mt-lg-0">
                <h2>Experienced Dr. Vandna</h2>
                <p>
                  Dr. Vandna Kanani is an experienced practitioner specializing
                  in predictive homeopathy, a pioneering approach focused on
                  uncovering and addressing the root causes of health issues.
                  With years of dedicated practice, Dr. Kanani has successfully
                  treated a wide range of conditions, including hypertension,
                  asthma, eczema, psoriasis, and acne. Her holistic,
                  personalized treatment plans go beyond merely alleviating
                  symptoms, aiming instead to enhance overall well-being. Dr.
                  Kanani's commitment to continuous learning and
                  patient-centered care has earned her a reputation for
                  excellence in the field of homeopathy, making her a trusted
                  healer for those seeking long-term health and wellness.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pb-5">
        <Container>
          <Row>
            <div className="section-title">
              <h1>Range of Conditions Treated:</h1>
            </div>
          </Row>
          <Row className="align-items-center">
            <Col lg={6}>
              <p>
                <strong>Hypertension:&nbsp;</strong>
                Managing high blood pressure to prevent heart disease and
                stroke.
              </p>
              <p>
                <strong>Asthma:&nbsp;</strong>
                Alleviating symptoms and improving respiratory health.
              </p>
              <p>
                <strong>Eczema:&nbsp;</strong>
                Providing relief from chronic skin inflammation and itching.
              </p>
              <p>
                <strong>Psoriasis:&nbsp;</strong>
                Reducing flare-ups and improving skin health.
              </p>
              <p>
                <strong>Acne:&nbsp;</strong>
                Treating persistent pimples, blackheads, and cysts.
              </p>
              <p>
                <strong>Migraines:&nbsp;</strong>
                Reducing the frequency and intensity of severe headaches.
              </p>
              <p>
                <strong>PCOD:&nbsp;</strong>
                Regulating menstrual cycles and managing hormonal imbalances.
              </p>
              <p>
                <strong>Infertility: &nbsp;</strong>
                Enhancing reproductive health and emotional well-being.
              </p>
              <p>
                <strong>Chronic Arthritis: &nbsp;</strong>
                Relieving pain and inflammation, improving joint mobility.
              </p>
              <p>
                <strong>Thyroid Imbalances:&nbsp;</strong>
                Balancing hormones and boosting energy levels.
              </p>
              <p>
                <strong>Kidney Stones: &nbsp;</strong>
                Preventing recurrence and supporting kidney function.
              </p>
              <p>
                <strong>Allergies:&nbsp;</strong>
                Identifying triggers and reducing allergic reactions.
              </p>
              <p>
                <strong>Digestive Issues:&nbsp;</strong>
                Addressing problems like IBS, constipation, and acid reflux.
              </p>
              <p>
                <strong>Autoimmune Disorders:&nbsp;</strong>
                Balancing the immune response and reducing flare-ups.
              </p>
              <p>
                <strong>Depression and Anxiety:&nbsp;</strong>
                Supporting mental health and emotional balance.
              </p>
              <p>
                <strong>Chronic Fatigue Syndrome:&nbsp;</strong>
                Boosting energy levels and improving overall vitality.
              </p>
              <p>
                <strong>Fibromyalgia:&nbsp;</strong>
                Managing widespread pain and fatigue.
              </p>
              <p>
                <strong>Menstrual Irregularities:&nbsp;</strong>
                Regulating cycles and alleviating discomfort.
              </p>
              <p>
                <strong>Urinary Tract Infections:&nbsp;</strong>
                Preventing recurrence and promoting urinary health.
              </p>
              <p>
                <strong>Insomnia:&nbsp;</strong>
                Improving sleep quality and patterns.
              </p>
              <p>
                <strong>Sinusitis:&nbsp;</strong>
                Reducing inflammation and alleviating sinus pain.
              </p>
              <p>
                <strong>Obesity:&nbsp;</strong>
                Supporting weight management and overall health.
              </p>
              <p>
                <strong>Hair Loss:&nbsp;</strong>
                Promoting hair growth and scalp health.
              </p>
              <p>
                <strong>Osteoporosis:&nbsp;</strong>
                Strengthening bones and preventing fractures.
              </p>
              <p>
                <strong>Diabetes:&nbsp;</strong>
                Managing blood sugar levels and preventing complications.
              </p>
              <p>
                <strong>High Cholesterol:&nbsp;</strong>
                Reducing cholesterol levels and supporting heart health.
              </p>
              <p>
                <strong>Varicose Veins:&nbsp;</strong>
                Improving circulation and reducing discomfort.
              </p>
              <p>
                <strong>Menopausal Symptoms:&nbsp;</strong>
                Alleviating hot flashes, mood swings, and other symptoms.
              </p>
              <p>
                <strong>Tonsillitis:&nbsp;</strong>
                Reducing inflammation and preventing recurrent infections.
              </p>
              <p>
                <strong>Pediatric Health Issues:&nbsp;</strong>
                Treating common childhood ailments.
              </p>
            </Col>
            <Col lg={6}>
              <p>
                <strong>ADHD:&nbsp;</strong>
                Supporting focus and behavior in children.
              </p>
              <p>
                <strong>GERD: &nbsp;</strong>
                Managing acid reflux and promoting digestive health.
              </p>
              <p>
                <strong>Chronic Bronchitis:&nbsp;</strong>
                Improving lung function and reducing symptoms.
              </p>
              <p>
                <strong>Eye Conditions:&nbsp;</strong>
                Treating issues like conjunctivitis and dry eyes.
              </p>
              <p>
                <strong>Rheumatoid Arthritis: &nbsp;</strong>
                Reducing joint inflammation and pain.
              </p>
              <p>
                <strong>Sciatica:&nbsp;</strong>
                Alleviating pain and improving mobility.
              </p>
              <p>
                <strong>PMS:&nbsp;</strong>
                Managing premenstrual syndrome symptoms.
              </p>
              <p>
                <strong>Ear Infections: &nbsp;</strong>
                Treating acute and chronic ear infections.
              </p>
              <p>
                <strong>Anemia: &nbsp;</strong>
                Improving iron levels and overall vitality.
              </p>
              <p>
                <strong>Back Pain:&nbsp;</strong>
                Alleviating chronic and acute back pain.
              </p>
              <p>
                <strong>Joint Pain:&nbsp;</strong>
                Managing pain and improving joint function.
              </p>
              <p>
                <strong>IBS: &nbsp;</strong>
                Providing relief from irritable bowel syndrome symptoms.
              </p>
              <p>
                <strong>Liver Disorders:&nbsp;</strong>
                Supporting liver health and function.
              </p>
              <p>
                <strong>Gallstones:&nbsp;</strong>
                Managing symptoms and preventing recurrence.
              </p>
              <p>
                <strong>Colds and Flu: &nbsp;</strong>
                Boosting immune response and reducing symptoms.
              </p>
              <p>
                <strong>Pneumonia:&nbsp;</strong>
                Supporting recovery and lung health.
              </p>
              <p>
                <strong>Lupus: &nbsp;</strong>
                Managing symptoms and supporting immune balance.
              </p>
              <p>
                <strong>Crohn's Disease: &nbsp;</strong>
                Alleviating digestive inflammation and discomfort.
              </p>
              <p>
                <strong>Ulcerative Colitis:&nbsp;</strong>
                Managing chronic digestive inflammation.
              </p>
              <p>
                <strong>Celiac Disease:&nbsp;</strong>
                Supporting digestive health and managing symptoms.
              </p>
              <p>
                <strong>Mouth Ulcers:&nbsp;</strong>
                Treating and preventing recurrence of mouth sores.
              </p>
              <p>
                <strong>Hemorrhoids:&nbsp;</strong>
                Reducing pain and inflammation.
              </p>
              <p>
                <strong>Gout:&nbsp;</strong>
                Managing uric acid levels and joint pain.
              </p>
              <p>
                <strong>Hyperthyroidism: &nbsp;</strong>
                Balancing thyroid function.
              </p>
              <p>
                <strong>Hypothyroidism:&nbsp;</strong>
                Supporting thyroid health and energy levels.
              </p>
              <p>
                <strong>Vertigo:&nbsp;</strong>
                Alleviating dizziness and improving balance.
              </p>
              <p>
                <strong>Chronic Sinusitis:&nbsp;</strong>
                Reducing chronic sinus inflammation.
              </p>
              <p>
                <strong>Sleep Apnea: &nbsp;</strong>
                Supporting respiratory health and improving sleep.
              </p>
              <p>
                <strong>Prostate Health:&nbsp;</strong>
                Managing symptoms of prostate enlargement.
              </p>
              <p>
                <strong>Neuropathy: &nbsp;</strong>
                Alleviating nerve pain and improving function.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Details;
