import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import homeopathy from "../../../Images/homeopathy.jpeg";
import "./About.css";

const About = () => {
  // useEffect(() => {
  //   AOS.init({
  //     duration: 1000,
  //   });
  //   AOS.refresh();
  // }, []);
  return (
    <section className="about-wrapper">
      <Container>
        <Row>
          <Col md={12} lg={6}>
            <div className="about-left">
              <img src={homeopathy} alt="donto" className="img-fluid donto" />
              {/* data-aos="fade-right" */}
              <div className="fun-fact-sec" >
                <div className="single-fun">
                  <span>10k</span>
                  <span>+</span>
                  <p>Happy Patients</p>
                </div>
                {/* data-aos="fade-right" */}
                <div className="single-fun sp-fun" >
                  <span>25</span>
                  <span>+</span>
                  <p>Countries Reached</p>
                </div>
                {/* data-aos="fade-left" */}
                <div className="single-fun" >
                  <span>7</span>
                  <span>+</span>
                  <p>Years Experience</p>
                </div>
                {/* data-aos="fade-left" */}
                <div className="single-fun sp-fun">
                  <span>5</span>
                  <span>+</span>
                  <p>Awards</p>
                </div>
                <span className="line"></span>
              </div>
            </div>
          </Col>
          <Col md={12} lg={6}>
            <div className="about-right mt-5 mt-lg-0">
            {/* data-aos="zoom-in" */}
              <div className="about-content text-start">
                <h1>Welcome to a Family</h1>
                <p className="m-auto content-inner">
                  Welcome to our homeopathic family! At Dr. Vandna Kanani’s
                  clinic, we believe in creating a warm and supportive
                  environment where patients feel like they are part of a caring
                  community. Our commitment to holistic healing and personalized
                  care extends beyond just treating symptoms—we aim to foster
                  lasting relationships based on trust, empathy, and compassion.
                </p>
                <br />
                <p className="m-auto content-inner">
                  <strong>Our Philosophy:&nbsp;</strong>
                  We understand that every patient is unique, with individual
                  needs and concerns. Our approach is centered on treating the
                  whole person, not just the illness. We take the time to
                  listen, understand, and address the root causes of health
                  issues, ensuring comprehensive and effective care.
                </p>
                <br />
                <p className="m-auto content-inner">
                  <strong>A Community of Care:&nbsp;</strong>
                  When you join us, you become part of a community that values
                  your well-being. Our dedicated team works collaboratively to
                  provide the best possible care, supporting you every step of
                  the way. From your first consultation to your follow-up
                  visits, we are here to guide you on your journey to better
                  health.
                </p>
                <br />
                <p className="m-auto content-inner">
                  <strong>Holistic Healing:&nbsp;</strong>
                  Our focus on predictive homeopathy allows us to offer
                  treatments that are tailored to your specific needs. We
                  believe in the power of natural remedies to stimulate the
                  body’s innate healing abilities, promoting not just physical
                  health but also emotional and psychological well-being.
                </p>
                <br />
                <p className="m-auto content-inner">
                  <strong>Continuous Support:&nbsp;</strong>
                  Healing is a continuous process, and we are committed to being
                  there for you throughout your journey. Our ongoing support
                  includes regular check-ins, adjustments to your treatment plan
                  as needed, and education on maintaining a healthy lifestyle.
                  We empower you with the knowledge and tools to take charge of
                  your health.
                </p>
                <br />
                <p className="m-auto content-inner">
                  <strong>Building Lasting Relationships:&nbsp;</strong>
                  At our clinic, we cherish the relationships we build with our
                  patients. We see ourselves not just as healthcare providers,
                  but as partners in your health journey. Our goal is to create
                  a welcoming space where you feel comfortable, understood, and
                  cared for.
                </p>
                <br />
                <p className="m-auto content-inner">
                  Thank you for choosing us to be part of your health journey.
                  We look forward to welcoming you to our family and supporting
                  you in achieving optimal health and well-being.
                </p>
                <br />
                <p className="m-auto content-inner">
                  Warm regards,
                  <br />
                  Dr. Vandna Kanani and the Team
                </p>
                {/* <a href='/page/about'>About Us</a> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
